import { View, Text, Touchable, Icon } from '@/components'
import { navigation, theme } from '@/config'
import { useEffect, useState } from 'react'

const data = [
  { text: 'Search', to: 'Home', icon: 'search', slug: '/' },
  { text: 'AI Chat', to: 'Home', icon: 'chat-square', slug: '/' },
  { text: 'Nutrition', to: 'Nutrition', icon: 'dine', slug: 'nutrition' },
  { text: 'Exercise', to: 'Exercise', icon: 'dumbbell', slug: 'exercise' },
]

export const TabBar = () => {
  const [currentUrl, setCurrentUrl] = useState<string | null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentUrl(window.location.href)
    }
  }, [])

  return (
    <View
      style={[
        'row',
        'absolute',
        'justifySpaceBetween',
        'fullWidth',
        'paddingVertical:2.5',
        'paddingHorizontal:4',
        'backgroundColor:neutral1',
        { bottom: 0, ...theme.effects.intense },
      ]}
    >
      {data.map(item => {
        const isSelected = currentUrl?.endsWith?.(item?.slug)
        return (
          <Touchable
            debugName={item.slug}
            key={item.slug}
            style={['center', 'column']}
            onPress={() => navigation.navigate(item?.to)}
          >
            <Icon
              name={item?.icon}
              style={['medium', 'marginBottom:0.5']}
              color={isSelected ? theme.colors.primary6 : theme.colors.neutral5}
            />
            <Text
              text={item?.text}
              style={{
                fontSize: 9,
                color: isSelected
                  ? theme.colors.primary6
                  : theme.colors.neutral5,
              }}
            />
          </Touchable>
        )
      })}
    </View>
  )
}
