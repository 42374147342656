import React from 'react'
import { createStyles } from '@codeleap/styles'
import { useBooleanToggle } from '@codeleap/common'
import { Image, Text, View, ActionIcon, Avatar } from '@/components'
import { AppImages, MAX_APP_WIDTH, navigation, OSAlert } from '@/config'
import { OPTIMUM_CHAT_CONSTANTS } from '../constants'

export type OptimumChatHeaderProps = {
  title: string
  hasMessages: boolean
  onReset?: () => void
}

export const OptimumChatHeader = ({
  hasMessages,
  title,
  onReset,
}: OptimumChatHeaderProps) => {
  const [visible, toggle] = useBooleanToggle(false)

  const onConfirmErase = () => {
    if (!hasMessages) {
      return OSAlert.info({
        title: 'No messages to be erased',
        onDismiss: toggle,
      })
    }

    OSAlert.ask({
      title: 'Erase conversation',
      body: 'Are you sure you want to erase this conversation? This action cannot be undone.',
      options: [
        {
          text: 'Cancel',
          onPress: toggle,
        },
        {
          text: 'Erase',
          style: 'destructive',
          onPress: () => {
            onReset()
            toggle()
          },
        },
      ],
    })
  }

  return (
    <View style={[styles.header, 'gradient']}>
      <View>
        <ActionIcon
          debugName="Header Back"
          name="chevron-left"
          style={['iconSize:4', 'bg:transparent']}
          onPress={() => {
            navigation.navigate('Exercise')
          }}
        />
        <View style={['alignCenter', 'marginLeft:2']}>
          <Image
            alt="Optimum Avatar"
            source={AppImages?.OptimumAvatar}
            style={styles.avatar}
          />
          <Text
            text={title}
            style={['h3', 'medium', 'color:neutral1', styles.title]}
          />
        </View>
      </View>

      <ActionIcon
        debugName="Erase conversation"
        icon="refresh"
        style={['iconSize:3', 'bg:transparent']}
        onPress={onConfirmErase}
      />
    </View>
  )
}

const { HEADER_HEIGHT } = OPTIMUM_CHAT_CONSTANTS

const IMAGE_SIZE = 110
const IMAGE_LEFT = 56

const styles = createStyles(theme => ({
  header: {
    ...theme.presets.relative,
    ...theme.presets.alignCenter,
    ...theme.presets.justifySpaceBetween,
    backgroundColor: theme.colors.neutral10,
    height: HEADER_HEIGHT,
    ...theme.spacing.paddingHorizontal(2),
    ...theme.spacing.margin(2),
    ...theme.spacing.marginBottom(0),
  },
  avatar: {
    ...theme.presets.absolute,
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    bottom: -(IMAGE_SIZE * 0.25),
    left: IMAGE_LEFT - theme.spacing.value(2),
  },
  title: {
    marginLeft: IMAGE_LEFT + theme.spacing.value(2),
  },
}))
