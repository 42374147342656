import { customTextStyles } from './Text'
import {
  ActionIconComposition,
  ButtonComposition,
  DrawerComposition,
} from '@codeleap/web'
import { AvatarComposition } from './Avatar'
import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

export type HeaderComposition =
  | 'wrapper'
  | 'innerWrapper'
  | `drawer${Capitalize<DrawerComposition>}`
  | `signInButton${Capitalize<ButtonComposition>}`
  | `menuIcon${Capitalize<ActionIconComposition>}`
  | 'navContentWrapper'
  | 'navItem'
  | 'navItem:selected'
  | 'logoWrapper'
  | 'logo'
  | 'profileWrapper'
  | 'profileInfos'
  | 'firstName'
  | 'email'
  | `avatar${Capitalize<AvatarComposition>}`

const createHeaderVariant = createStyles<HeaderComposition>

export const BREAKPOINT = 'tabletSmall'
const logoSize = 113

export const HeaderStyles = {
  default: createHeaderVariant(theme => ({
    wrapper: {
      position: 'sticky',
      zIndex: 2,
      top: theme.spacing.value(0),
      left: theme.spacing.value(0),
      right: theme.spacing.value(0),
      backgroundColor: theme.colors.header,
      backdropFilter: 'blur(4px)',
      height: theme.values.itemHeight.default,
      minHeight: theme.values.itemHeight.default,
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
    },
    innerWrapper: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
    },
    logo: {
      height: theme.values.itemHeight.default / 2,
      width: logoSize,
    },
    logoWrapper: {},
    drawerBox: {
      ...theme.presets.column,
      ...theme.presets.alignCenter,
      gap: theme.spacing.value(2),
      backgroundColor: theme.colors.background,
      alignItems: 'stretch',
    },
    drawerCloseButtonIcon: {
      color: theme.colors.neutral10,
    },
    drawerCloseButtonTouchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
      marginLeft: 'auto',
    },
    profileWrapper: {
      borderRadius: theme.borderRadius.small,
      ...theme.border({
        color: theme.colors.neutral5,
        width: theme.values.borderWidth.small,
      }),
      ...theme.presets.centerRow,
      ...theme.spacing.padding(2),
      ...theme.presets.fullWidth,
      ...theme.spacing.gap(2),
      ...theme.spacing.marginBottom(1),
    },
    profileInfos: {
      width: `calc(100% - ${
        theme.values.itemHeight.small + theme.spacing.value(3)
      }px)`,
      ...theme.presets.column,
      ...theme.presets.justifySpaceBetween,
      ...theme.spacing.gap(0.5),
    },
    email: {
      ...customTextStyles('p3'),
      color: theme.colors.neutral8,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    firstName: {
      ...customTextStyles('h3'),
    },
    signInButtonWrapper: {
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.paddingHorizontal(2),
      ...theme.spacing.paddingVertical(1),
      height: theme.values.itemHeight.small,

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.fullWidth,
        height: theme.values.itemHeight.default,
      },
    },
    signInButtonText: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: '500',
    },
    signInButtonIcon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],

      [theme.media.down(BREAKPOINT)]: {
        width: theme.values.iconSize[2],
        height: theme.values.iconSize[2],
      },
    },
    menuIconTouchableWrapper: {
      marginLeft: 'auto',
      background: 'transparent',
    },
    menuIconIcon: {
      marginLeft: 'auto',
      color: theme.colors.neutral10,
    },
    navItem: {
      ...customTextStyles('h4'),
      ...theme.presets.textCenter,
      color: theme.colors.neutral3,
      fontWeight: '600',
      textDecoration: 'none',

      [theme.media.down(BREAKPOINT)]: {
        width: '100%',
        ...customTextStyles('p1'),
        ...theme.presets.textLeft,
        ...theme.spacing.padding(2),
        backgroundColor: theme.colors.transparent,
        borderRadius: theme.borderRadius.small,
        color: theme.colors.neutral1,
        fontWeight: '400',
      },
    },
    'navItem:selected': {
      color: theme.colors.primary3,

      [theme.media.down(BREAKPOINT)]: {
        backgroundColor: theme.colors.primary1,
        color: theme.colors.primary3,
        fontWeight: '600',
      },
    },
    navContentWrapper: {
      gap: theme.spacing.value(4),
      ...theme.presets.center,

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
        gap: theme.spacing.value(2),
        ...theme.presets.alignStart,
        ...theme.spacing.paddingVertical(3),
      },
    },
    avatarWrapper: {
      [theme.media.down(BREAKPOINT)]: {
        width: 50,
        height: 50,
        minHeight: 50,
        minWidth: 50,
      },
    },
  })),
}

StyleRegistry.registerVariants('Header', HeaderStyles)
