import { Button, Link, Modal, Text } from "..";
import { ModalProps } from "@codeleap/web";
import { BaseOptionPageType } from "../BaseOptimumPage";
import { COOKIES_KEYS, theme } from "@/config";
import Cookies from "js-cookie";

type DemoWarningModalProps = {
  type: BaseOptionPageType
} & ModalProps;

export const DemoWarningModal = ({
  visible,
  toggle,
  type,
}: DemoWarningModalProps) => {

  const pageName = type === 'Nutrition' ? 'Nutritional' : type

  return (
    <Modal
      title={`${pageName} Screen Example`}
      visible={visible}
      toggle={toggle}
      showClose={false}
      style={["centered", "demoWarningModal"]}
    >
      <Text style={['p3-inter','textCenter', 'marginTop:-1.5', 'marginBottom:2']}>
          This screen shows placeholder
          <strong> {pageName} </strong> data, not live or your own data. It's an example of what a user might see.
      </Text>
      <Button
        debugName="demo warning modal - got it button"
        text="View example now"
        style={["large", "demoWarningModalButton"]}
        onPress={toggle}
      />
    </Modal>
  );
};
