import { AppImages, navigation, React, theme } from "@/config";
import { View, Text, Avatar, Button, Image } from "@/components";
import { createStyles } from "@codeleap/styles";
import { ButtonProps } from "@codeleap/web";
import { AppStatus } from "@/redux";

type OptimumCardProps = {
  origin: "Exercise" | "Nutrition";
};

const FooterButton = (props: Partial<ButtonProps>) => (
  <Button
    {...props}
    debugName="Optimum card button"
    style={[
      "flex",
      "backgroundColor:neutral10",
      'blenderProButton',
      {
        "&:hover": {
          backgroundColor: theme.colors.neutral10,
        },
      },
      { borderRadius: 0, text: { color: theme.colors.neutral1 } },
    ]}
  />
);

export const OptimumCard = ({ origin = "Nutrition" }: OptimumCardProps) => {
  const isNutritionPage = origin === "Nutrition";

  const description = isNutritionPage
    ? "You’re running low on protein 🫙. Will I order some more for next week’s new workout & nutritional plans ? 🚚📦💪"
    : "Today’s workout day. Here’s a link to your favourite workout playlist 💪🎶";
  const buttonText = isNutritionPage ? "ORDER PROTEIN" : "WORKOUT PLAYLIST";

  return (
    <View style={["column", "margin:2", "padding:1", { breakpoints: { tablet: ['padding:0']}}]}>
      <View style={[styles.header, "alignCenter", "padding:2", "relative"]}>
        <Image
          source={AppImages.OptmumMood.smiley}
          style={styles.headerOptimum}
        />
        <View
          style={[
            "column",
            "marginLeft:11.5",
            "gap:1",
            { breakpoints: { tiny: ['gap:0', 'marginLeft:10'] }}
          ]}
        >
          <Text
            text="Hello,"
            style={[
              "h3",
              "color:neutral1",
              "bold",
              {
                breakpoints: {
                  mobile: ["h4", "color:neutral1"],
                  tiny: ["h5", "color:neutral1"],
                },
              },
            ]}
          />
          <Text
            text={`I'm Coach Optimum`}
            style={[
              "h3",
              "color:neutral1",
              "bold",
              { whiteSpace: 'nowrap' },
              {
                breakpoints: {
                  mobile: ["h4", "color:neutral1"],
                  tiny: ["h5", "color:neutral1"],
                },
              },
            ]}
          />
        </View>
      </View>

      <View style={[styles.content, "padding:2", "column"]}>
        <Text
          text="Today's message"
          style={["h5", "color:primary7", "marginBottom:1", "bold"]}
        />
        <Text
          text={description}
          style={["p2", "color:neutral8", "marginBottom:2"]}
        />

        <View style={["gap:1", "fullWidth"]}>
          <FooterButton
            text={buttonText}
            onPress={() => AppStatus.setModal(["demoWarning", true])}
          />
          <FooterButton
            text={"ASK COACH"}
            onPress={() => navigation.navigate("Home")}
          />
        </View>
      </View>
    </View>
  );
};

const styles = createStyles((theme) => ({
  header: {
    background:
      "linear-gradient(to left, rgba(219, 92, 57, 1),rgba(202, 17, 22, 1))",
  },
  content: {
    backgroundColor: `rgba(239, 238, 236, 1)`,
  },
  headerOptimum: {
    width: 100,
    height: 100,
    left: 0,
    bottom: -5,
    position: "absolute",
    [theme.media.down('tiny')]: {
      width: 95,
      height: 95,
      bottom: 0,
    }
  },
}));
