import { COOKIES_KEYS, Settings, theme } from '@/config'
import Cookies from 'js-cookie'
import { Button, Modal, TextInput } from '..'
import { createForm, useForm, useEffect } from '@codeleap/common'
import { AppStatus, useAppSelector } from '@/redux'

const passwordForm = createForm('password', {
  password: {
    type: 'text',
    password: true,
  },
})

export const PasswordAccessManager = () => {
  const { visible } = useAppSelector(store => store.AppStatus.modals.password)

  const onToggleModal = () => AppStatus.setModal(['password', !visible])

  useEffect(() => {
    const hasEnteredPassword = Cookies.get(COOKIES_KEYS.HAS_ENTERED_PASSWORD)
    if (!hasEnteredPassword) {
      onToggleModal()
    }
  }, [])

  const form = useForm(passwordForm, {})

  const onSubmitPassword = () => {
    if (form.values.password === Settings.SitePassword) {
      Cookies.set(COOKIES_KEYS.HAS_ENTERED_PASSWORD, 'true')
      onToggleModal()
    }
  }

  return (
    <Modal
      visible={visible}
      toggle={onToggleModal}
      showClose={false}
      style={['centered', 'passwordModal']}
    >
      <TextInput
        {...form.register('password')}
        visibilityToggle
        placeholder="Enter the password"
        style={[
          {
            innerWrapper: {
              border: `1px solid ${theme.colors.neutral5}`,
            },
          },
        ]}
      />
      <Button
        text={'Submit'}
        style={['demoWarningModalButton']}
        onPress={onSubmitPassword}
        debugName="Password manager - on submit password attempt"
      />
    </Modal>
  )
}

export default PasswordAccessManager
