import { throttle } from 'lodash'
import Lottie from 'lottie-react'
import { onUpdate } from '@codeleap/common'
import { createStyles } from '@codeleap/styles'
import { AppImages, MAX_APP_WIDTH } from '@/config'
import { OptimumChatSuggestions } from './Suggestions'
import { OptimumChatMessage, OptimumMoods } from '@/types'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { getFlatlistProps, onScrollNearTopReversed } from '@/utils'
import { OptimumChatMessageCard } from '../../OptimumChatMessageCard'
import { View, OptimumMoodCard, ActivityIndicator, Button } from '@/components'

const MemoizedCard = React.memo(OptimumChatMessageCard)
const FETCH_NEXT_PAGE_THRESHOLD = 200

export type OptimumChatMessagesProps = {
  optimumInitialMessage: OptimumChatMessage
  messagesQuery: any
  talking: boolean
  loading: boolean
  scrollRef: any
  onSuggestionPress: (suggestion: string) => void
  hasCompletedOnboarding: boolean
}

const FinishedPlanAnimation = ({
  isConfetti = false,
  isFirework = false,
}: {
  isConfetti: boolean
  isFirework: boolean
}) => {
  const visible = isConfetti || isFirework
  const lottieRef = useRef(null)

  const animation =
    AppImages[isConfetti ? 'ConfettiAnimation' : 'FireworkAnimation']

  const MAX_LOOPS = 3

  onUpdate(() => {
    if (!visible) {
      lottieRef.current?.stop?.()
    } else {
      lottieRef.current?.play?.()
    }
  }, [visible])

  return (
    <View style={styles.confettiAnimationWrapper}>
      <Lottie
        autoPlay={false}
        lottieRef={lottieRef}
        loop={MAX_LOOPS}
        animationData={animation}
        style={{ width: '100%', height: '100%' }}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
          progressiveLoad: true,
        }}
      />
    </View>
  )
}

export const OptimumChatMessages = ({
  optimumInitialMessage,
  messagesQuery,
  talking,
  loading,
  scrollRef,
  onSuggestionPress,
  hasCompletedOnboarding,
}) => {
  const listProps = getFlatlistProps<OptimumChatMessage>(messagesQuery)
  const messages = listProps?.data || []
  const hasNextPage = !!listProps?.currentNext

  const messagesArray = useMemo(() => {
    if (hasNextPage) return messages
    if (!hasCompletedOnboarding) return messages
    return [...messages, optimumInitialMessage]
  }, [hasNextPage, messages, hasCompletedOnboarding])

  const renderItem = useCallback(
    ({ item, index }) => {
      const lastMessage = messagesArray?.[index - 1]
      const firstCharsInMessage = item?.message?.slice(0, 3)
      return (
        <MemoizedCard
          key={`${item.id}-${firstCharsInMessage}`}
          {...item}
          isContinuous={lastMessage?.role == item.role}
          talking={talking}
          isInitialMessage={messagesArray?.length === 1}
          hasCompletedOnboarding={hasCompletedOnboarding}
        />
      )
    },
    [messagesArray, talking, hasCompletedOnboarding]
  )

  const handleScroll = throttle(e => {
    if (
      !listProps.hasNextPage ||
      listProps.isFetchingNextPage ||
      loading ||
      talking
    )
      return

    onScrollNearTopReversed(
      e,
      listProps.fetchNextPage,
      FETCH_NEXT_PAGE_THRESHOLD
    )
  }, 200)

  const lastMessage = messagesArray?.[0]

  const isConfetti = lastMessage?.assistant_data?.workout_plan_finished
  const isFirework = lastMessage?.assistant_data?.nutrition_plan_finished

  const getMood = (): OptimumMoods => {
    if (talking) return 'normal'
    if (loading) return 'thinking'
    if (!lastMessage?.assistant_data) return 'smiley'
    if (isConfetti) return 'confetti'
    return lastMessage?.assistant_data?.mood
  }

  const suggestions = lastMessage?.assistant_data?.suggestions

  return (
    <View style={styles.scroll} ref={scrollRef} onScroll={handleScroll}>
      {listProps?.isLoading ? (
        <View style={['flex', 'center']}>
          <ActivityIndicator debugName="Loading chat messages list" />
        </View>
      ) : (
        <>
          <FinishedPlanAnimation
            isConfetti={isConfetti}
            isFirework={isFirework}
          />

          <OptimumMoodCard
            mood={getMood()}
            title={
              loading ? 'Coach Optimum is thinking...' : "I'm here to help!"
            }
            description={
              loading ? null : 'Answer questions and watch my reaction.'
            }
          />

          {!loading && !talking && (
            <OptimumChatSuggestions
              suggestions={suggestions}
              onSuggestionPress={onSuggestionPress}
              optimumInitialMessage={optimumInitialMessage}
              hasCompletedOnboarding={hasCompletedOnboarding}
            />
          )}

          {messagesArray?.map((item, index) => renderItem({ item, index }))}
        </>
      )}
    </View>
  )
}

const styles = createStyles(theme => ({
  scroll: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column-reverse',
    ...theme.presets.relative,
    padding: theme.spacing.value(2),
  },
  confettiAnimationWrapper: {
    position: 'fixed',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: MAX_APP_WIDTH,
    height: '90svh',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
}))
