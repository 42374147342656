import { AppImages } from '@/config'
import { ColorUtils } from '@/utils'
import { createStyles } from '@codeleap/styles'
import { View, Text, Image } from '@/components'
import { OptimumMoods } from '@/types'

export type OptimumMoodCardProps = {
  mood?: OptimumMoods
  title: string
  description?: string
}

export const OptimumMoodCard = ({
  title,
  description,
  mood = 'normal',
  ...rest
}: OptimumMoodCardProps) => {
  return (
    <View style={styles.wrapper} {...rest}>
      <View style={[styles.card, 'gradient']}>
        <Image
          alt="Optmimum Mood"
          source={AppImages.OptmumMood[mood] || AppImages.OptmumMood.normal}
          style={styles.moodImage}
        />

        <View style={styles.textWrapper}>
          <Text text={title} style={['p4', 'bold', 'color:neutral1']} />

          {!!description && (
            <Text
              text={description}
              style={['c2', 'medium', 'color:neutral1', 'preWrap']}
            />
          )}

          <Text
            text="Powered by AI technology"
            style={['p5', 'color:neutral1']}
          />
        </View>
      </View>
    </View>
  )
}

const IMAGE_WIDTH = 119
const TINY_IMAGE_WIDTH = 100
const CARD_HEIGHT = 81
const IMAGE_HEIGHT = 107

const styles = createStyles(theme => ({
  wrapper: {
    paddingTop: theme.spacing.value(2),
    ...theme.presets.relative,
    ...theme.presets.fullWidth,
  },
  card: {
    ...theme.presets.relative,
    ...theme.presets.alignCenter,
    ...theme.presets.fullWidth,
    height: CARD_HEIGHT,
    paddingLeft: IMAGE_WIDTH,

    [theme.media.down('tiny')]: {
      paddingLeft: TINY_IMAGE_WIDTH,
    },
  },
  textWrapper: {
    flex: 1,
    ...theme.presets.column,
    marginLeft: theme.spacing.value(2),

    [theme.media.down('tiny')]: {
      marginLeft: theme.spacing.value(0.5),
    },
  },
  moodImage: {
    ...theme.presets.absolute,
    bottom: 0,
    left: 0,
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    margin: theme.spacing.value(0.5),

    [theme.media.down('tiny')]: {
      width: TINY_IMAGE_WIDTH,
    },
  },
}))
