export const IS_SSR = typeof window === 'undefined'

export const DefaultDateFormat = 'dd/MM/yyyy'

export const MAX_APP_WIDTH = 500

export const COOKIES_KEYS = {
  HAS_ENTERED_PASSWORD: 'has_entered_password',
  OPTIMUM_CHAT: 'optimum_chat',
  OPTIMUM_CHAT_ONBOARDING: 'optimum_chat_onboarding',
  OPTIMUM_NUTRITION_ONBOARDING: 'optimum_nutrition_onboarding',
  OPTIMUM_EXERCISE_ONBOARDING: 'optimum_exercise_onboarding'
}
