import '../config/styles/global.css'
import '../config/styles/theme'
import '../config/styles'
import '../components'

import type { AppProps } from 'next/app'
import { AppWrapper } from '@/components'
import { navigation } from '@/config'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const pathname = usePathname()

  navigation.context.router = router
  navigation.context.pathname = pathname


  return (
    <AppWrapper>
      <Component {...pageProps} />
    </AppWrapper>
  )
}
