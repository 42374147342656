import { AppImages, theme } from '@/config'
import { createStyles } from '@codeleap/styles'
import {
  Image,
  Text,
  View,
  Button,
  Modal,
  Pager,
  FakeHeader,
  Header,
} from '@/components'
import { PropsOf, useState } from '@codeleap/common'

export type OptimumChatOnboardingProps = PropsOf<typeof Modal>

const data = [
  {
    title: 'Personalised Workouts',
    description:
      'Get a workout plan with exercises aligned to your fitness level and goals.',
    button: 'Next',
    image: AppImages.OptimumOnboarding1,
  },
  {
    title: 'Protein Calculator',
    description: `Discover your daily protein needs with Coach Optimum's calculator.`,
    button: 'Next',
    image: AppImages.OptimumOnboarding2,
  },
  {
    title: 'Explore the Demo',
    description: `Chat now with Coach Optimum for a preview of how these fitness & nutritional tools will work.`,
    button: 'Get started',
    image: AppImages.OptimumOnboarding3,
  },
]

const Item = ({ title, description, image }) => (
  <View style={[styles.wrapper]}>
    <View style={styles.content}>
      <View style={styles.imageWrapper}>
        <Image alt="Chat onboarding" source={image} style={styles.image} />
      </View>

      <Text
        text={title}
        style={[
          'h2',
          'color:neutral1',
          'semiBold',
          'marginBottom:1',
          {
            breakpoints: {
              tiny: {
                fontSize: 20,
              },
            },
          },
        ]}
      />

      <Text
        text={description}
        style={[
          'h5',
          'color:neutral3',
          'regular',
          { maxWidth: '75%', breakpoints: { tiny: { fontSize: 12 } } },
        ]}
      />
    </View>
  </View>
)

export const OptimumChatOnboarding = ({
  toggle,
  ...rest
}: OptimumChatOnboardingProps) => {
  const [page, setPage] = useState(0)

  const onNextPage = () => {
    if (page === data.length - 1) {
      return toggle()
    }
    setPage(state => state + 1)
  }

  const onChangePage = page => setPage(page)

  const isLastPage = page === data.length - 1

  const buttonText = isLastPage ? 'Get started' : 'Next'

  return (
    <Modal toggle={toggle} style={['fullscreen']} showClose={false} {...rest}>
      <View style={styles.background} />

      <View style={styles.header}>
        <Header />
      </View>

      <Pager
        page={page}
        dots={true}
        onChange={onChangePage}
        style={['optimumOnboarding']}
        footer={
          <View style={['center']}>
            <Button
              text={buttonText}
              debugName="Chat Onboarding Get Started"
              style={['large', { width: 150, borderRadius: 0 }]}
              onPress={onNextPage}
            />
          </View>
        }
      >
        {data.map(item => (
          <Item
            key={item.title}
            {...item}
            page={page}
            onNextPage={onNextPage}
            onChangePage={onChangePage}
          />
        ))}
      </Pager>
    </Modal>
  )
}

const HEADER_HEIGHT = 72

const styles = createStyles(theme => ({
  wrapper: {
    flex: 1,
    ...theme.presets.fullWidth,
    ...theme.presets.relative,
    ...theme.presets.column,
    ...theme.presets.alignCenter,
    ...theme.presets.justifyCenter,
    height: '80svh',
    paddingTop: HEADER_HEIGHT,
  },
  header: {
    position: 'absolute',
    top: 0,
    height: HEADER_HEIGHT,
    ...theme.presets.fullWidth,
  },
  content: {
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    ...theme.spacing.paddingHorizontal(4),
  },
  background: {
    flex: 1,
    ...theme.presets.absolute,
    background:
      'linear-gradient(to bottom, rgba(219, 92, 57, 1), rgba(202, 17, 22, 1))',
    height: `calc(100svh - ${HEADER_HEIGHT}px)`,
    width: '100%',
    zIndex: -1,
    border: `8px solid ${theme.colors.neutral1}`,
    bottom: 0,
  },
  logo: {
    width: HEADER_HEIGHT,
  },
  imageWrapper: {
    ...theme.presets.relative,
    ...theme.presets.fullWidth,
    height: `35svh`,
    ...theme.spacing.marginTop(4),
    ...theme.spacing.marginBottom(5),
  },
  image: {
    ...theme.presets.fullWidth,
    ...theme.presets.fullHeight,
    scale: 1.25,
  },
}))
